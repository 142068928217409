.th-sidebar-container{
    display:flex;
}
main{
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
}
.th-sidebar{
    background: #121212;
    color: #ffffff;
    height: 100vh;
    transition: all 0.5s;
    z-index: 1050;
    display: flex;
    flex-direction: column;
}
.th-sidebar-menu{
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.th-sidebar-menu::-webkit-scrollbar{
display: none;
}
.th-top-section{
    z-index: 11;
    display: flex;
    align-items:center;
    padding:16px 15px;
}
.th-logo{
    font-size: 20px;
}
.th-bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    margin: 20px 0px;
    gap: 15px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: background-color 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
.link::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #143374 6.05%, #65C37A 105.65%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: left 0.6s ease-in-out;
  }


.link:hover::before {
    left: 0;
  }

.link:hover .th-icon{
    color: #fff;
}
.link:hover .th-link-text{
    color: #fff;
}
.active{
    background: linear-gradient(90deg, #143374 6.05%, #65C37A 105.65%);
    color: #fff;
}

.active .th-icon{
    color: #fff;
}
.active .th-link-text{
    color: #fff;
}
/* .icon{
    font-size: 16px;
    color: #2D7966;
} */

.th-icon,.th-link-text{
    font-size: 16px;
    color: #fff;
}


.th-mobile-bars{
    z-index: 1999;
    background-color: #001529;
    top: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-size: 18px;
}

.background {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #ff0000, #ffff00);
    transition: background 0.5s ease-in-out;
  }
  
  .background:hover {
    background: linear-gradient(to right, #ffff00, #ff0000);
  } 


  .th-sidebar-container-padding {
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  
  @media only screen and (max-width: 768px) {
    .th-sidebar-container-padding {
      padding-left: 2%;
      padding-right: 2%;
    }
  }