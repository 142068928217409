.snackbar {
    display: none;
    z-index: 2001;
    padding: 7px 30px;
    background-color: #fff;
    transform: translateX(10%);
    color: #333;
    border-radius: 5px;
    opacity: 0;
    animation: pop-in 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }
  
  .snackbar.show {
    opacity: 1;
    display: block;
  }
  
  .snackbar .message {
    display: inline-block;
  }
  
  .snackbar .close {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }

  @keyframes pop-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .snackbar-container{
    position: fixed;
    top: 110px;
    z-index: 2001;
  }