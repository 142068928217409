.th-form-control{
    display: block;
      width: 100%;
      height: 45px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: rgb(73, 80, 87);
      background-color: rgb(243, 243, 243);
      background-clip: padding-box;
      border: 0px;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
      margin-top: 10px;
  }

  .th-form-control-1{
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cacaca;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 400;
    outline: none;
  }


  .th-form-control-2{
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cacaca;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 400;
    outline: none;
  }

  textarea{
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cacaca;
    padding: 10px 14px;
    font-size: 16px;
    font-weight: 400;
    outline: none;
  }
  
  /* input:focus-visible {
    outline: none!important
  }
   */
  .th-form-control-sm{
    height: 30px !important;
    font-size: 0.9rem !important;
  }

  .th-form{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: fixed; 
    bottom: 0;
    
}

@media only screen and (max-width:768px){
    .th-form{
        width: 100%;
    }
}

.eye{
    position: absolute;
    right: 7%;
    z-index: 1;
    top: 45%;
  }



.th-login-input{
box-sizing: border-box;
height: 45px;
background: #FFFFFF;
border: 2px solid #F0F0F0;
border-radius: 5px;
padding: 15px;
outline: none;
}

.th-enquiry-input{
  box-sizing: border-box;
  height: 45px;
  background: #FFFFFF;
  border: 2px solid #F0F0F0;
  border-radius: 5px;
  padding: 15px;
  outline: none;
  }

  .th-enquiry-input select{
    height: auto;
  }

.th-login-input:focus {
  border: 4px solid transparent;
  border-image: linear-gradient(90deg, #143374 6.05%, #65C37A 134.68%) 1;
}
/* .th-login-input:focus::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid transparent;
  border-image: linear-gradient(to right, #00c6ff, #0072ff) 1;
  border-radius: 5px;
} */

label{
  color: #252525;
}

.th-login-input::placeholder{
  color: rgba(37, 37, 37, 0.3);
  }

