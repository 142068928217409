.th-primary-1{
    color: #143374;
}

.th-primary-2{
    color: #65C37A;
}

.th-secondary-1{
    color: #4B6FA1;
}

.th-secondary-2{
    color: #A0D9B3;
}






.th-primary-bg-1{
    background: #143374;
}

.th-primary-bg-2{
    background: #65C37A;
}

.th-secondary-bg-1{
    background: #4B6FA1;
}

.th-secondary-bg-2{
    background: #A0D9B3;
}

.th-neutral-bg-1{
    background: #F0F0F0;
}

.th-neutral-bg-2{
    background: #3C3C3C;
}

.th-neutral-bg-3{
    background: #FFFFFF;
}


.th-neutral-bg-4{
  background: #121212;
}

.th-logo-font{
    color: #143374;
    font-size: 40px;
    font-weight: 700;
}

.th-h1{
    color:#252525;
    font-weight: 600;
}

.th-h2{
    color:#252525;
    font-weight: 500;
}

.th-h3{
    color:#252525;
    font-weight: 400;
}

.th-p{
    color:#252525;
    font-weight: 400;
}

.th-gradient{
    background: linear-gradient(90deg, #143374 6.05%, #65C37A 134.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .th-gradient-india{
    /* background:   linear-gradient(90deg, #FF671F 29.51%, rgba(255, 255, 255, 0.49664) 36.43%, #046A38 43.27%); */
    background: linear-gradient(to bottom, #ffd8b3, #ff9933, #ffffff, #138808, #000080);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .th-gradient-hr-1{
    border-image: linear-gradient(90deg, #143374 6.05%, #65C37A 134.68%) 1;
  }

  .th-gradient-hr-2{
    border-image: linear-gradient(90deg, #65C37A 6.05%, #143374 134.68%) 1;
  }

  .th-get-btn{
    background: linear-gradient(90deg, #143374 0%, rgba(20, 51, 116, 0.77) 105.32%);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transition: border-bottom-color 0.3s ease-in-out, transform 0.3s ease-in-out;

}

.th-get-btn:hover {
    background-color: #143374;
    border-bottom-color: #65C37A;
    animation: bounce 0.5s ease-in-out;
}

.th-get-btn-1{
  background: linear-gradient(90deg, #65C37A 0%, rgba(101, 195, 122, 0.77) 105.32%);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: border-bottom-color 0.3s ease-in-out, transform 0.3s ease-in-out;

}

.th-get-btn-1:hover {
    background-color: #65C37A;
    border-bottom-color: #143374;
    animation: bounce 0.5s ease-in-out;
}
  

@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-4px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-2px);
    }
    100% {
      transform: translateY(0);
    }
  }


  .th-advisor-badge{
    height: 35px;
    width: 35px;
    border-radius: 35px;
    display: flex;
    background-color: #FF8282;
    justify-content: center;
    align-items: center;
  }

  .th-explorer-badge{
    height: 35px;
    width: 35px;
    border-radius: 35px;
    display: flex;
    background-color: #99D7A7;
    justify-content: center;
    align-items: center;
  }
/* new home page, new steps */
.th-steps-img-sm{
  width: 169px;
}

@media (min-width: 576px){
  .th-steps-img{
    width: 250px;
  }
}

.th-rotate-90 {
  transform: rotate(90deg);
}

/* footer */
.th-footer, .th-header {
  font-family: 'Josefin Sans', sans-serif !important;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap') format('truetype'); /* Adjust the path based on your folder structure and font file format */
  /* Add additional font-face declarations for different font styles and weights if needed */
}


.th-card-invite-social{
  box-shadow: 0px 12px 20px 0px #5C688014;
}

.text-gradient{
  background: linear-gradient(90deg, #143374 6.05%, #65C37A 105.65%);
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.th-explorer{
  background-color: #65C37AA8;
  color: #000000;
}

.th-advisor{
  background-color: #FF8282;
  color: #000000;
}

.th-black-header .ant-table-thead .ant-table-cell {
  background-color: #252525;
  color: #ffffff;
}

.th-underline-image{
    display: inline-block;
    background-image: url(../img/underline.svg);
    background-position: center bottom;
    background-repeat: repeat-x;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  align-items: center;
}