@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400..700&display=swap');
body {
  overflow-x: hidden !important;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  font-family: 'Inter',sans-serif;
  color: #000000;
  background:white; 
}

::-webkit-scrollbar {
  width: 3px !important; /* Set the width of the scrollbar */
  
}

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
::-webkit-scrollbar-thumb {
  background-color: #888 !important; /* Color of the scrollbar thumb */
  border-radius: 3px !important;
}

::-webkit-scrollbar-track {
  width: 3px !important;
  border-radius: 3px !important;
  background-color: #f0f0f0; /* Color of the scrollbar track */
}
@media screen and (max-width: 767px) {
  body {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
  body::-webkit-scrollbar {
    display: none !important;
  }
}

a:hover {
  text-decoration: none;
  color: inherit;
}
button:focus {
  outline: none;
}
.th-inter {
  font-family: "Inter", sans-serif;
}

/* font color */
.th-black {
  color: #000000 !important;
}

.th-grey {
  color: #6a6a6a;
}

.th-grey-1 {
  color: #252525;
}

.th-grey-2 {
  color: #f4f4f4;
}

.th-white {
  color: #ffffff;
}

.th-primary {
  color: #2D7966;
}

.th-yellow {
  color: #ffb500;
}
.th-blue {
  color: #3f4d90;
}
.th-clinic {
  color: #547AF5;
}
.th-text-hint {
  color: #747b8d;
}
.th-text-hint-1 {
  color: #5D5D5D;
}

.th-text-error{
  color: red;
}

/* Link */
.th-link {
  color: #000000;
}

.th-link:hover {
  text-decoration: none;
  /* color: #609A33; */
}

.th-link:active {
  text-decoration: none;
  /* color: #609A33; */
}

.th-link-1:hover {
  text-decoration: none;
  color: #ffffff;
}

.th-link-black {
  color: #000000;
}

.th-link-black:hover {
  text-decoration: none;
  color: #000000;
}

.th-link-black:active {
  text-decoration: none;
  color: #000000;
}

/* BUTTON */
.th-btn-primary {
  background-color: #143374;
  color: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  outline: none;
  border: none;
}

.th-btn-course {
  background-color: #fff;
  color: #5D5D5D;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: none;
  border: 2px solid #5D5D5D;
}

.th-btn-course-disabled{
  color: #f7a1a1;
  background-color: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: none;
  border: 2px solid #f7a1a1;
}

/* .th-btn-white {
  background-color: #ffffff;
  color: #000000;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
} */
.th-btn-black {
  background-color: #000000;;
  color: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
}
.th-btn-white:focus,
.th-btn-primary:focus,.th-btn-black:focus {
  outline: none;
}

.th-btn-white {
  background-color: #fff;
  border: 2px solid #F0F0F0;
  border-radius: 5px;
  color: #252525;
}

/* BG Color */
.th-bg-white {
  background: #ffffff;
}
.th-bg-primary {
  background: #2D7966;
}
.th-bg-color-1 {
  background: #d9dded;
}
.th-bg-main{
  background:#efefef;
}
.th-bg-grey-1 {
  background: #f4f4f4;
}
.th-bg-yellow {
  background: #ffc81a;
}
.th-bg-clinic{
  background: #004cff;
}
.th-bg-appointment{
  background: #059086;
}
.th-bg-blue {
  background: #52509d;
}
/* Input */
.th-input :focus {
  box-shadow: none !important;
  outline: none;
}

/* Gradient */
.th-primary-gradient {
  background: linear-gradient(145.22deg, #ffc033 62.28%, #609A33 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.th-blue-gradient {
  background: linear-gradient(145.22deg, #49549a 62.28%, #191f44 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.th-search-input:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  .th-hidden-sm {
    display: none !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .th-hidden-md {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .th-hidden-lg {
    display: none !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.th-no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.th-no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

label {
  margin-bottom: 0 !important;
}


.th-contact-margin {
  margin-top: -180px;
}
@media screen and (min-width: 768px) {
  .th-contact-margin {
    margin-top: -210px;
  }
}
.th-pb-0 {
  padding-bottom: 0px !important;
}

/* HEADER */
.th-header-underline {
  border-bottom: #609A33 3px solid;
}

.th-header-dropbtn {
  background-color: rgba(0, 0, 0, 0);
  /* color: white; */
  /* padding: 16px; */
  /* font-size: 16px; */
  border: none;
  cursor: pointer;
}

.th-header-dropdown {
  position: relative;
  display: inline-block;
}

.th-header-dropdown-content {
  display: none;
    position: absolute;
    left: -163px;
}



.th-header-dropdown-content .links {
  color: black;
  padding: 12px 0px;
  text-decoration: none;
  display: block;
}

.th-mobile-menu-sub-content .links{
  color: black;
  padding: 12px 0px;
  text-decoration: none;
  display: block;
}

/* .th-header-dropdown-content a:hover {
  background-color: #ddd; */
/* } */

.th-header-dropdown:hover .th-header-dropdown-content {
  display: block;
}

.th-header-dropdown-subcontent{
  margin-top: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  min-width: 500px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  
}

/* .th-header-dropdown:hover .th-header-dropbtn {
  color: #609A33;
} */

/* Drawer */
.th-side-drawer-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  top: 0;
  left: 0;
}

.th-side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  z-index: 2000;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.th-pb-0 {
  padding-bottom: 0px !important;
}

.th-border-patch-blue {
  border-left: 8px solid #3f4d90;
}
.th-border-patch-yellow {
  border-left: 8px solid #ffc81a;
}
.th-border-patch-clinic {
  border-left: 8px solid #004cff;
}
.th-border-patch-orange {
  border-left: 8px solid #609A33;
}
.th-side-drawer.open {
  transform: translateX(0%);
}

.slick-next::before {
  content: "\203A" !important;
  color: #000e !important;
  background: #cccccc;
  padding: 0 16px 12px 16px;
  border-radius: 25px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.slick-prev:before {
  content: "\2039" !important;
  color: #000 !important;
  background: #cccccc;
  padding: 0 16px 12px 16px;
  border-radius: 25px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.th-school-bg-color {
  background-color: #ffffff;
}
@media screen and (max-width: 768px) {
  .th-school-bg-color {
    background-color: #f3f3f3;
  }
}


.slick-prev, .slick-next {
  font-size: 15px !important;
}

 .slick-prev:before, .slick-next:before  {
  content: '' !important;
  display: none;
}

.th-bg-img{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.th-bg-img-1{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.th-container {
  padding-left: 8%;
  padding-right: 8%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 1400px) {
  .th-container {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media only screen and (max-width: 768px) {
  .th-container {
    padding-left: 4%;
    padding-right: 4%;
  }
}

.slick-list {
  padding: 0px;
  border-radius: 0px;
}

.th-bg-duration{
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 10px;
  right: 10px;
}

.th-border-bottom-course{
  border-bottom: 1px solid #f4f4f4;
}

.th-border-bottom-gradient{
  border-bottom: 3px solid;
  border-image: linear-gradient(314deg, #143374 6.05%, #65C37A 134.68%) 1;
}



@media only screen and (min-width: 768) {
  .th-responsive-header{
    font-size: 20px;
  }
  
}

.th-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
}

.th-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  box-shadow:rgb(0 0 0 / 8%) 0px 1px 8px 0px;
  z-index: 2000;
  /* padding: 1.5rem; */
}


.th-modal-content {
  
}

.th-vt-hr {
  border-left: 1px solid rgb(0 0 0 / 8%);
  /* height: 100px;  */
}

.flatpickr-input{
  display:none ;
}


.th-custom-calendar .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
  background: #2d7966;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #2d7966;
}

.th-custom-calendar .flatpickr-day.selected:hover{
  background: #2d7966;
  
}

.th-time-slot{
  border: 1px solid #2d7966;
  min-width: 110px;
  cursor: pointer;
  color: #2D7966;
  font-weight: 600;
}

.th-time-slot-selected{
  /* border: 1px solid #2d7966; */
  min-width: 110px;
  cursor: pointer;
  color: #ffffff;
  background-color: #2D7966;
  font-weight: 600;
}


/* width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 4px;
  border-radius: 10px;
}

/* track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 100px;
}

/* handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.th-italic{
  font-style: italic;
}


button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.th-pointer{
  cursor: pointer;
}

/* sign up page */
/* Chrome, Safari, Edge, Opera */
.sign-up-contact input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.sign-up-contact input[type=number] {
  -moz-appearance: textfield;

}


.th-right-gradient-border{
  border: 1px solid;
  border-bottom: none;
  border-right: none;
  border-top: none;
  border-image: linear-gradient(314deg, #143374 6.05%, #65C37A 134.68%) 1;
}

@media only screen and (min-width: 768) {
  .th-right-gradient-border{
    border: 0px !important;
  }
  
}

.th-gradient-hr{
  width: 1px;
  background: linear-gradient(314deg, #143374 6.05%, #65C37A 134.68%);
}


.th-border-active{
    border: 2px solid !important;
    border-image: linear-gradient(to right, #143374, #65C37A) 1 !important;
}

.th-icon-button{
  box-shadow: 0 5px 14px rgba(0,0,0,.1)!important;
  display: block;
  min-width: 3.33rem;
  height: 3.33rem;
  line-height: 3.33rem;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  font-size: 18px;
  transition: all .3s ease-in-out;
  color: #143374;
  margin-top: 0.66rem;
  outline: none;
}

.th-navigate-page-btn{
    position: absolute !important;
    bottom: 4%;
    z-index: 40;
    left: 4%;
}

.th-navigate-page-btn-next{
  position: absolute !important;
  bottom: 4%;
  z-index: 40;
  right: 4%;
}

.th-w-card{
  width: auto !important;
}
.th-ant-space .ant-space-item{
  width: auto !important;
}

@media (max-width: 576px) {
  .th-w-card{
    width: 100% !important;
  }
  .th-ant-space .ant-space-item{
    width: 100% !important;
  }
}


.th-store-image a img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.th-gold-blue-gradient{
  background-image: url(../img/gradients/gold-blue.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


.th-feedback-shadow{
  box-shadow: 0px 12px 20px 0px rgba(92, 104, 128, 0.08), 0px 4px 6px 0px rgba(92, 104, 128, 0.08);
}

.th-hidden-scrollbar::-webkit-scrollbar {
  width: 0px !important;
  
}

.th-program-card-shadow{
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: 1px solid #65C37A;
}

