
.th-header-shadow{
    box-shadow: 0px 4px 6px rgba(92, 104, 128, 0.08), 0px 12px 20px rgba(92, 104, 128, 0.08);
}

.th-img-logo img{
    height: 65px;
}

.th-small-hr{
    background: #E9E9EB;
    height: 15px;
    width: 1px;
}

.th-small-hr-light{
    background: #D1D1D1;
    height: 15px;
    width: 1px;
}


.th-sticky-header{
   position: sticky;
   top: 10px; 
   z-index: 10;

}


.th-join-btn{
    background: #2B60CE;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5), 0px 12px 20px rgba(0, 0, 0, 0.5);
}

.th-header-bg{
    background: linear-gradient(90deg, #143374 6.05%, #65C37A 134.68%);
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header{
    padding: 0px;
    padding-inline-start: 0px;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px;
}

.ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box {

    padding-bottom: 6px;
    padding-top: 2px;
}

.th-mobile-menu{
    position: relative;
  }
  
  .th-mobile-menu-content {
    visibility:hidden;
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s ease;
  }
  
  .th-mobile-menu-sub-content {
      margin-left: -13px;
      padding: 10px;
      background: white;
      margin-top: 25px;
      border-radius: 10px;
      min-width: 250px;
      
  }
  
  .th-mobile-menu:hover .th-mobile-menu-content {
    visibility: visible;
    opacity: 1;
  }


  .th-mobile-user-menu{
    position: relative;
  }
  
  .th-mobile-user-menu-content {
    visibility:hidden;
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s ease;
  }
  
  .th-mobile-user-menu-sub-content {
      margin-left: -217px;
      padding: 10px;
      background: white;
      margin-top: 25px;
      border-radius: 10px;
      min-width: 250px;
      
  }
  
  .th-mobile-user-menu:hover .th-mobile-user-menu-content {
    visibility: visible;
    opacity: 1;
  }