// font sizes
@for $i from 1 through 100 {
    .th-#{$i} {
      font-size: $i + px !important;
    }
  }
  
  // font weight
  @for $i from 100 through 900 {
    @if $i % 100==0 {
      .th-fw-#{$i} {
        font-weight: $i !important;
      }
    }
  }

//   border radius
@for $i from 1 through 100 {
    .th-br-#{$i} {
      border-radius: $i + px !important;
    }
  }

//   gap
@for $i from 0 through 96 {
    .th-gap-#{$i} {
      gap: ($i*4) + px !important;
    }
  }