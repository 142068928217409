.image-container {
    position: relative; 
    height: 320px; 
    background-size: cover;
    background-position: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s; 
  }
  
  .image-container:hover .overlay {
    opacity: 0.8;
    background-color: #609A33; /* change to your desired transparent color */
  }

  .white-hr {
    border-top: 3px solid #fff;
    z-index: 1;
    width: 50%;
    margin: 0 auto;
  }

  .black-hr {
    border-top: 1px solid #000;
    width: 30%;
    margin-left: 3px;
  }
  
  .transparent-bg{
    position: relative;
  }

  .transparent-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.5); /* adjust the opacity as needed */
  }

  .th-text-change{
    color: #2D7966;
  }

  .th-text-change-black{
    color: #000;
  }

  .th-change-color{
    background-color: #ffffff;
    transition: 0.3s;
  } 
  .th-change-color:hover{
    background-color: #2D7966;
  }

  .th-change-color:hover .th-text-change{
    color: #ffffff;
  }

  .th-change-color:hover .th-text-change-black{
    color: #ffffff;
  }

  .th-change-color:hover .th-text-hint-change{
    color: #ffffff;
  }

  .th-text-hint-change{
    color: #747b8d;
  }

.store a{
  color: black;
}